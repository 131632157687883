import countries from "../json/CountryCodes.json"

export const computeAddress = ({ addressLine1, addressLine2, addressLine3, suburb, postcode, state }) => {
  const address = [addressLine1, addressLine2, addressLine3].filter(item => item).join(", ")
  return [address, suburb, postcode, state].filter(item => item).join(", ")
};

export function getCountryDialCode(countryCode) {
  return countries.find(({ code }) => code === countryCode)?.dial_code;
}

// function returnFileSize(number) {
//   if (number < 1024) {
//     return `${number} bytes`;
//   } else if (number >= 1024 && number < 1048576) {
//     return `${(number / 1024).toFixed(1)} KB`;
//   } else if (number >= 1048576) {
//     return `${(number / 1048576).toFixed(1)} MB`;
//   }
// }

// export function validFileType(file) {
//   if (file.size >= MAX_SIZE_BYTES) {
//     window.alert("Invalid file size. File cannot exceed 40MB");
//     return false;
//   }

//   const fileTypes = ["image/jpeg", "image/png", "image/tif"];
//   if(!fileTypes.includes(file.type)){
//     window.alert("Invalid file type");
//     return false
//   }

//   return true
// }