import React from "react";
import AppHeader from "../components/AppHeader";
import {
  getApplications,
  deleteApplicationItem,
  //validateApplicationItem,
  putApplication,
  postApplication,
  getAccessToken,
} from "../api/services";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormDialog from "../components/FormDialog";
import Box from "@mui/material/Box";
import AdminMenu from "../components/AdminMenu";
import { validateNewContact, createNewContact, createNewTrademark, uploadAttachment } from "../api/trademark-services";
import { getNewContactPayload } from "../utils/getNewContactPayload";
import { getNewTrademarkPayload } from "../utils/getNewTrademarkPayload";
import { withAuth } from "../HOC";

function Admin() {
  const [applications, setApplications] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [targetApplication, setTargetApplication] = useState({});
    
  const fetchApplications = async () => {
    setIsFetching(true);
    const response = await getApplications();
    console.log(response);
    setApplications(response);
    setIsFetching(false);
  };

  const handleCreate = () => {
    setTargetApplication({});
    setIsOpen(true);
  };

  const handleEdit = (application) => {
    setIsOpen(true);
    setTargetApplication(application);
  };

  const handleDelete = async ({ id }) => {
    // Replace with dialog delete confirmation
    submitDelete(id);
  };

  // VALIDATE
  const handleValidateNewContact = async (application) => {
    const payload = getNewContactPayload(application);
    const isValid = await validateNewContact(payload)
    if(isValid){
      window.alert("Payload is valid")
    } else {
      window.alert("Invalid Contact information")
    }
  };

  const handleValidateAttachment = async () => {
    submitAttachment()
  }

  const handleValidateNewTrademark = async (application) => {
    const identifier = await submitNewContact(application);
    const payload = getNewTrademarkPayload(application, identifier);
    const response = await createNewTrademark(payload);
    console.log(response)
  };
  
  // Submit
  const submitNewContact = async (application) => {
    const payload = getNewContactPayload(application);
    const data = await createNewContact(payload);
    console.log(data)
    if (data) {
      window.alert("Created contact: " + data.identifier)
      return data.identifier;
    } else {
      window.alert("Error creating new contact")
      throw new Error("Error creating new contact")
    }
  };

  const submitAttachment = async () =>{
    window.alert("TBD");
    const formData = new FormData()
    formData.append("ipRightType", "TRADE_MARKS")
    formData.append("attachmentType", "Image")
    formData.append("fileName", "test.png")
    formData.append(
      "file",
      new File(["Hello, world!"], "hello.png", {
        type: "image/png",
      })
    );
    const response = await uploadAttachment(formData)
    console.log(response)
  }

  // const submitNewTrademark = async (application, identifier) => {
  //   const payload = getNewTrademarkPayload(application, identifier);
  //   const response = await createNewTrademark(payload);
  //   console.log(response)
  // };

  const submitDelete = async (id) => {
    // window.alert("Disabled")
    const response = await deleteApplicationItem(id);
    console.log(response);
    fetchApplications();
  };

  const handleSubmit = async (payload) => {
    const response = payload.id
      ? await putApplication(payload, payload.id)
      : await postApplication(payload);
    console.log(response);
    fetchApplications();
    setIsOpen(false);
  };

  // Dialog
  const handleCloseDialog = (_, reason) => {
    if (reason === "backdropClick") return;
    setIsOpen(false);
  };

  const fetchIpToken = async () => {
    const token = await getAccessToken();
    localStorage.setItem("ipToken", token);
  };

  // NOTE: Watch JSON structure when refactoring as it is stored as string
  const SelectionComponent = ({selections}) => {
    return selections?.map(({ classNumber, classDescriptions }, i) => (
      <div key={i}>{classNumber}: {classDescriptions?.join(", ")}</div>
    ));
  };

  return (
    <div>
      <AppHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 8px",
        }}
      >
        <Button
          onClick={() => {
            fetchApplications();
            fetchIpToken();
          }}
          variant="outlined"
          disabled={isFetching}
        >
          {isFetching ? "Loading..." : "Get Applications"}
        </Button>
        {/* Get Applications button */}
        <FormDialog
          open={isOpen}
          targetApplication={targetApplication}
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleCloseDialog={handleCloseDialog}
        />
      </Box>
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table
          id="adminTable"
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">Action</TableCell>
              <TableCell as="th">Id</TableCell>
              <TableCell as="th">Paid</TableCell>
              <TableCell as="th">Trademark Type</TableCell>
              <TableCell as="th">Trademark Text</TableCell>
              <TableCell as="th">Owner Type</TableCell>
              <TableCell as="th">Owner/Company Name</TableCell>
              <TableCell as="th">First Name</TableCell>
              <TableCell as="th">Last Name</TableCell>
              <TableCell as="th">ABN/ACN</TableCell>
              <TableCell as="th">Email</TableCell>
              <TableCell as="th">Phone</TableCell>
              <TableCell as="th">Address1</TableCell>
              <TableCell as="th">Address2</TableCell>
              <TableCell as="th">Address3</TableCell>
              <TableCell as="th">Suburb</TableCell>
              <TableCell as="th">Postcode</TableCell>
              <TableCell as="th">State</TableCell>
              <TableCell as="th">Country</TableCell>
              <TableCell as="th">Logo</TableCell>
              <TableCell as="th">Selections</TableCell>
              <TableCell as="th">TOS</TableCell>
              <TableCell as="th">Created</TableCell>
              <TableCell as="th">Modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((note) => (
              <TableRow key={note.id}>
                <TableCell>
                  <AdminMenu
                    onEdit={() => handleEdit(note)}
                    onDelete={() => handleDelete(note)}
                    onValidateNewContact={() => handleValidateNewContact(note)}
                    onValidateAttachment={() => handleValidateAttachment(note)}
                    onValidateNewTrademark={() =>
                      handleValidateNewTrademark(note)
                    }
                  />
                </TableCell>
                <TableCell>{note.id}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>{note.trademarkType}</TableCell>
                <TableCell>{note.trademarkText}</TableCell>
                <TableCell>
                  {note.ownerType === 1 ? "Company" : "Individual"}
                </TableCell>
                <TableCell>{note.ownerName}</TableCell>
                <TableCell>{note.firstName}</TableCell>
                <TableCell>{note.lastName}</TableCell>
                <TableCell>{note.abnAcn}</TableCell>
                <TableCell>{note.email}</TableCell>
                <TableCell>{note.phone}</TableCell>
                <TableCell>{note.addressLine1}</TableCell>
                <TableCell>{note.addressLine2}</TableCell>
                <TableCell>{note.addressLine3}</TableCell>
                <TableCell>{note.suburb}</TableCell>
                <TableCell>{note.postcode}</TableCell>
                <TableCell>{note.state}</TableCell>
                <TableCell>{note.country}</TableCell>
                <TableCell>
                  {note.trademarkLogo && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={note.trademarkLogo}
                    >
                      Link
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  <SelectionComponent
                    selections={
                      note.selections ? JSON.parse(note.selections) : []
                    }
                  />
                </TableCell>
                <TableCell>{note.hasAgreedToTOS ? "True" : "False"}</TableCell>
                <TableCell>
                  {note.createdDate.slice(0, 19).replace("T", " ")}
                </TableCell>
                <TableCell>
                  {note.modifiedDate?.slice(0, 19).replace("T", " ")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withAuth(Admin);
