// import { useRef, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from 'react'
import Home from "./routes/Home";
import Admin from "./routes/Admin";
import ErrorPage from "./routes/ErrorPage";
import About from "./routes/About";
import Faq from "./routes/Faq";
import Contact from "./routes/Contact";
import TermsOfService2 from "./routes/TermsOfService2";
import Privacy2 from "./routes/Privacy2";
import Application from "./Application";
import OrderConfirmed from "./routes/OrderConfirmed";
import Blog from "./routes/Blog";
import Post0 from "./routes/blogs/Post0";
import Post1 from "./routes/blogs/Post1";
import Post2 from "./routes/blogs/Post2";
import Post3 from "./routes/blogs/Post3";
import Post4 from "./routes/blogs/Post4";
import Post5 from "./routes/blogs/Post5";
import Post6 from "./routes/blogs/Post6";
import Login from "./routes/Login";
import AddressBook from "./routes/AddressBook";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "admin",
    element: <Admin />,
  },
  {
    path: "address-book",
    element: <AddressBook />,
  },
  {
    path: "faq",
    element: <Faq />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "blog",
    element: <Blog />,
  },
  {
    path: "may-the-fourth-be-with-you",
    element: <Post0 />,
  },
  {
    path: "trademark-filings-in-australia-are-up",
    element: <Post1 />,
  },
  {
    path: "au-domain-names",
    element: <Post2 />,
  },
  {
    path: "international-womens-day-2022",
    element: <Post3 />,
  },
  {
    path: "phasing-out-of-the-australian-official-journal-of-trade-marks",
    element: <Post4 />,
  },
  {
    path: "malaysia-joins-the-madrid-protocol-for-international-trademarks",
    element: <Post5 />,
  },
  {
    path: "canada-joins-the-madrid-protocol-for-international-trademarks",
    element: <Post6 />,
  },
  {
    path: "contact-us",
    element: <Contact />,
  },
  {
    path: "trademarkable/trademark",
    element: <Application />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfService2 />,
  },
  {
    path: "privacy-policy",
    element: <Privacy2 />,
  },
  {
    path: "order/orderConfirmed",
    element: <OrderConfirmed />,
  },
]);

function App() {

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
