import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
export default function Post4() {
  return (
    <div id="page" class="site">
      <AppHeader />
      <div id="content" class="site-content">
        <section class="section-blog">
          <div class="container">
            <div
              id="post4"
              class="blog-post_feature"
            ></div>
          </div>
          <div class="container container-md">
            <div class="page-content">
              <h1>
                Phasing out of the Australian Official Journal of Trade Marks
              </h1>
              <p>
                The Australian Register is required to publish the status,
                actions and outcomes of any trademark application. These
                publications are currently done in the Australian Official
                Journal of Trade Marks.
              </p>
              <p>
                However, from 8 June 2021,&nbsp;the{" "}
                <a href="https://search.ipaustralia.gov.au/trademarks/search/advanced" target="_blank" rel="noreferrer">
                  Australian Trade Mark Search
                </a>{" "}
                (ATMS) database will function will as the official journal and
                each publication will be presented in the ATMS database.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
