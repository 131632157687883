import React from "react";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";

export default function Post0() {
  return (
    <div id="page" class="site">
      <AppHeader />
      <div id="content" class="site-content">
        <section class="section-blog">
          <div class="container">
            <div id="post-0" class="blog-post_feature"></div>
          </div>
          <div class="container container-md">
            <div class="page-content">
              <h1>May the fourth be with you!</h1>
              <p>Happy Star Wars Day!</p>
              <p>
                There are more than 70 registered trade marks in Australia
                related to Star Wars. These trademarks have helped Star Wars
                become one of the most successful franchises of all time.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
