import axios from "axios";
const HOST =
  "https://test.api.ipaustralia.gov.au/public/ipright-management-b2b-api/v1";

// 0. Validate Contact
export const validateNewContact = async (payload) => {
  try {
    console.log("Validating payload", payload)
    const skipFlag = true; // Choose freetext address
    const url = `${HOST}/me/contacts?acceptInvalidAddressIndicator=${skipFlag}`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.ipToken}`,
        "Consent-Flag": true,
        "IPA-Validation-Only": true,
      },
    };
    const response = await axios.post(url, payload, options);
    console.log(response.data.message)
    // window.alert(response.data.message);
    return true;
  } catch (error) {
    console.log(error)
    window.alert(error.response.data.errorMessages[0].message);
    return false;
  }
};

// 1. Create Contact
export const createNewContact = async (payload) => {
  try {
    const skipFlag = true; // Choose freetext address
    const url = `${HOST}/me/contacts?acceptInvalidAddressIndicator=${skipFlag}`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.ipToken}`,
        "Consent-Flag": true,
      },
    };
    const response = await axios.post(url, payload, options);
    console.log(response.data.message);
    return response.data;
  } catch (error) {
    window.alert(error.message);
    console.error(error.response.data.errorMessages[0].message);
  }
};

//2. Create Trademark
export const createNewTrademark = async (payload) => {
  const url = `${HOST}/trade-marks`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.ipToken}`,
      "IPA-Validation-Only": true, // set to false to transact
    },
  };
  
  try {
    const response = await axios.post(url, payload, options);
    console.log(response);
    return response.data
  } catch (error) {
    console.log(error)
    window.alert(error.response.data.errorMessages[0].message);
    return false;
  }
};

// TBD
export const uploadAttachment = async (payload) => {
  const url = `${HOST}/attachments`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };
  
  try {
    const response = await axios.post(url, payload, options);
    console.log(response);
    return response
  } catch (error) {
    console.log(error.response.data);
    return error.response.data
  }
};

export const getAddressBook = async () => {
  const url = `${HOST}/me/contacts`;
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.ipToken}`,
    },
  };
  try {
    const response = await axios.get(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteContact = async (id) => {
  const url = `${HOST}/me/contacts/${id}`;
  const options = {
    headers: {
      "Consent-Flag": true,
      Authorization: `Bearer ${localStorage.ipToken}`,
      "Content-Type": "application/json",
    },
    data: ""
  };
  try {
    const response = await axios.delete(url, options);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
