import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { withAuth } from "../HOC";
import AppHeader from "../components/AppHeader";
import { deleteContact, getAddressBook } from "../api/trademark-services";

function getAddress({ addressLine1, addressLine2, addressLine3 }) {
  return [addressLine1, addressLine2, addressLine3]
    .filter((item) => item)
    .join(", ");
}

function AddressBook() {
  const [contacts, setContacts] = React.useState([]);

  const handleGetContacts = async () => {
    const response = await getAddressBook();
    setContacts(response.data.results);
    console.log(response.data.results);
  };

  const handleContactDelete = async(id) => {
    const response = await deleteContact(id)
    console.log(response);
    if(response.status === 202)
      handleGetContacts();
  }

  return (
    <div>
      <AppHeader />
      <div className="gutter">
        <p>
          <b>Address Book</b>
        </p>
        <Button variant="outlined" onClick={handleGetContacts}>
          Get Contacts
        </Button>

        <Table
          id="addressBook"
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell as="th">Action</TableCell>
              <TableCell as="th">identifier</TableCell>
              <TableCell as="th">displayName</TableCell>
              {/* <TableCell as="th">firstName</TableCell>
            <TableCell as="th">familyName</TableCell> */}
              <TableCell as="th">emailAddress</TableCell>
              <TableCell as="th">phoneNumber</TableCell>
              <TableCell as="th">addressLine(1|2|3)</TableCell>
              <TableCell as="th">cityOrTownName</TableCell>
              <TableCell as="th">postalOrZipCode</TableCell>
              <TableCell as="th">stateOrTerritoryOrCounty</TableCell>
              <TableCell as="th">countryCode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.identifier}>
                <TableCell>
                  <button
                    onClick={() => handleContactDelete(contact.identifier)}
                  >
                    Del
                  </button>
                </TableCell>
                <TableCell>{contact.identifier}</TableCell>
                <TableCell>{contact.displayName}</TableCell>
                {/* <TableCell>{contact.firstName}</TableCell>
              <TableCell>{contact.familyName}</TableCell> */}
                <TableCell>{contact.electronicAddress?.emailAddress}</TableCell>
                <TableCell>{contact.electronicAddress?.phoneNumber}</TableCell>
                <TableCell>{getAddress(contact.physicalAddress)}</TableCell>
                <TableCell>{contact.physicalAddress.cityOrTownName}</TableCell>
                <TableCell>{contact.physicalAddress.postalOrZipCode}</TableCell>
                <TableCell>
                  {contact.physicalAddress.stateOrTerritoryOrCounty}
                </TableCell>
                <TableCell>{contact.physicalAddress.countryCode}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
export default withAuth(AddressBook);
