import * as React from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DropzoneArea } from "mui-file-dropzone";
import ErrorNotification from "./components/ErrorNotification";

const MAX_SIZE_BYTES = 1024 * 1000 * 40; // B*kB*MB
// eslint-disable-next-line
export const ALPHA_NUMERIC_DASH_REGEX = /^[ ~`!@#$%^&*()_+=\[\]\\{}|;':",.\/<>?a-zA-Z0-9-]+$/;
// eslint-disable-next-line
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  
export default function ApplicationTradeMarkDetails({
  setFormValues,
  formValues,
  logoError,
  setLogoError,
}) {
  const [errorMessage, setErrorMessage] = React.useState("")

  const handleChange = (e) => {
    const obj = {
      [e.target.name]: e.target.value,
    };
    // Handle non english input
    const value = e.target.value;

    if(value.length >= 255) {
      setErrorMessage("Max character length is 255")
    } else {
      setErrorMessage("")
    }

    if (
      e.target.name === "trademarkText" &&
      value !== "" &&
      !ALPHA_NUMERIC_DASH_REGEX.test(value)
    ) {
      console.log("Invalid char");
      return;
    }

    if (e.target.name === "trademarkType" && e.target.value === "1")
      setFormValues((prev) => ({ ...prev, trademarkLogo: undefined }));
    if (e.target.name === "trademarkType" && e.target.value === "2")
      setFormValues((prev) => ({ ...prev, trademarkText: "" }));

    setFormValues((prev) => ({ ...prev, ...obj }));
  };

  const handleFileChange = (files) => {
    setLogoError("");
    setFormValues((prev) => ({ ...prev, trademarkLogo: files[0] }));
    document
      .querySelector(".drop-zone .preview-item:nth-of-type(2) button")
      ?.click();
  };


  return (
    <div id="trademarkDetailsSection">
      <Grid container>
        <h2>Your trademark</h2>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <FormControl>
            <FormLabel id="radio-option-1">
              What do you want to register as a trademark?*
            </FormLabel>
            <RadioGroup
              aria-labelledby="radio-option-1"
              name="trademarkType"
              value={formValues.trademarkType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="One word or one phrase in plain text"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="A single logo (with or without text)"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {formValues.trademarkType === "1" ? (
          <Grid item xs={12}>
            <p>
              <b>Type the word or phrase you would like to trademark</b>
            </p>
            <TextField
              required
              id="trademarkText"
              name="trademarkText"
              label="Enter the word or phrase in plain text"
              fullWidth
              variant="outlined"
              value={formValues.trademarkText}
              onChange={handleChange}
            />
            {errorMessage && <ErrorNotification message={errorMessage} />}
            <br />
            <br />
            <div className="trademark-preview">{formValues.trademarkText}</div>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <p>
              <b>Upload your logo</b>
            </p>
            <p>
              Logos must be in JPEG (.jpg, .jpeg) or PNG (.png) and at least 158 x 158 pixels
              <br />
              The file cannot exceed 40MB in size.
            </p>
            <br />
            <ErrorNotification message={logoError} />
            <DropzoneArea
              initialFiles={
                formValues.trademarkLogo ? [formValues.trademarkLogo] : []
              }
              onChange={handleFileChange}
              filesLimit={1}
              dropzoneText={"Click to upload or drag and drop here"}
              acceptedFiles={["image/jpeg", "image/png"]}
              maxFileSize={MAX_SIZE_BYTES}
              dropzoneClass={`drop-zone ${logoError && "error"}`}
              showAlerts={true}
              previewGridClasses={{
                container: "preview-container",
                item: "preview-item",
                image: "preview-image",
              }}
              dropzoneParagraphClass="drop-zone-paragraph"
              Icon={() => <CloudUploadIcon fontSize="large" />}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
