import * as React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Pill from "./components/Pill";
import PillDelete from "./components/PillDelete";
import RemoveIcon from "@mui/icons-material/Remove";
import "./ApplicationGoodsAndServices.css";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import tradeMarkClasses from "./json/classes/tradeMarkClassNames.json";
import LoadingContent from "./components/LoadingContent";
// import searchData from "./json/searchDataSecondDraft.json";
// import { matchSorter } from "match-sorter";
import { searchClass } from "./api/services";
import { CLASS_COST } from "./Application";
import { ALPHA_NUMERIC_DASH_REGEX } from "./ApplicationTradeMarkDetails";

const tradeMarkMap = () => {
  return Object.fromEntries(
    tradeMarkClasses.results.map(({ gsClassNumber, name }) => [gsClassNumber, name])
  );
};

const SearchResultListItem = ({
  name,
  number,
  id,
  suggestions,
  picked,
  handleAdd,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <li key={id}>
      <span className="trademark-class-title">
        Class {number} {name}
      </span>
      <div
        className={`trademark-class-item-list ${isExpanded ? "expanded" : ""}`}
      >
        {suggestions
          .filter((suggestion) => !picked[suggestion.id])
          .map((suggestion) => (
            <Pill
              key={suggestion.id}
              handleAdd={handleAdd}
              suggestion={suggestion}
            />
          ))}
      </div>
      {suggestions.length > 10 && (
        <Button
          sx={{ textTransform: "initial", fontWeight: "bold" }}
          size="small"
          onClick={toggleExpand}
          endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          Show {isExpanded ? "Less" : "More"}
        </Button>
      )}
    </li>
  );
};

export default function ApplicationGoodsAndServices({
  setSelections,
  selections,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(null);
  const [picked, setPicked] = React.useState({});
  const [isSearching, setIsSearching] = React.useState(false)

  const fetchSearch = async () => {
    try {
      const response = await searchClass(searchTerm);
      return response.data.results;
    } catch (e) {
      return [];
    }
  }

  const handleSearchChange = (e) => {
    const {value} = e.target;
    const match = ALPHA_NUMERIC_DASH_REGEX.test(value);
    if (match || value === "") setSearchTerm(value);
  };

  const handleSearchSubmit = async () => {
    if(!searchTerm) return;
    
    setIsSearching(true);
    const searchData = await fetchSearch();
    setIsSearching(false);

    const tradeMarkClassMap = tradeMarkMap();
    const hash = {};
    searchData.forEach((item, index) => {
      if (hash[item.classNumber]) {
        hash[item.classNumber].suggestions.push({
          id: index,
          class_number: item.classNumber,
          name: item.description,
          class_name: tradeMarkClassMap[item.classNumber],
        });
      } else {
        hash[item.classNumber] = {
          id: item.classNumber,
          order: index,
          number: item.classNumber,
          name: tradeMarkClassMap[item.classNumber],
          suggestions: [
            {
              id: index,
              class_number: item.classNumber,
              name: item.description,
              class_name: tradeMarkClassMap[item.classNumber],
            },
          ],
        };
      }
    });

    const searchDataTransform = Object.keys(hash)
      .map((key) => hash[key])
      .sort((a, b) => a.order - b.order);

    setSearchResults(searchDataTransform);
  };

  const handleAdd = (item) => {
    console.log(item);
    const clone = structuredClone(selections);
    const obj = clone.find(
      (clonedItem) => clonedItem.number === item.class_number
    );
    if (obj) obj.suggestions.push(item);
    else {
      clone.push({
        number: item.class_number,
        name: item.class_name,
        suggestions: [item],
      });
    }
    setSelections(clone);
    addPicked(item.id);
  };

  const handleRemoveClass = (suggestion) => {
    const filtered = selections.filter(
      ({ number }) => suggestion.number !== number
    );
    setSelections(filtered);
  };

  const handleRemove = (item) => {
    let clone = structuredClone(selections);
    const obj = clone.find(
      (clonedItem) => clonedItem.number === item.class_number
    );
    if (obj?.suggestions.length === 1) {
      clone = clone.filter(
        (clonedItem) => clonedItem.number !== item.class_number
      );
    } else {
      obj.suggestions = obj.suggestions.filter(
        (suggestion) => suggestion.id !== item.id
      );
    }
    setSelections(clone);
    removePicked(item.id);
  };

  const addPicked = (id) => {
    setPicked((prev) => {
      return { ...prev, [id]: true };
    });
  };

  const removePicked = (id) => {
    setPicked((prev) => {
      return { ...prev, [id]: false };
    });
  };

  return (
    <div id="goodsAndServices">
      <h2>Select Goods & Services</h2>
      <ul className="description">
        <li>
          Search for the goods and/or services that apply to your trademark.
        </li>
        <li>There is an official fee of ${CLASS_COST} per class.</li>
        <li>
          There is no additional cost for adding multiple items in the same
          class.
        </li>
      </ul>
      <div className="ac-container">
        <div className="search-container ac-column">
          <div className="ac-header">
            <InputBase
              id="search"
              disabled={isSearching}
              onKeyDown={(e) => e.key === "Enter" && handleSearchSubmit()}
              onChange={handleSearchChange}
              value={searchTerm}
              sx={{ ml: 1, flex: 1, background: "white" }}
              placeholder="Enter a single word or phrase (e.g. shoe)"
              inputProps={{ "aria-label": "search phrase" }}
            />
            <IconButton
              disabled={isSearching}
              onClick={handleSearchSubmit}
              type="button"
              sx={{ position: "absolute", p: "12px", right: "12px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </div>
          <ul className="search-results-list">
            {isSearching ? (
              <LoadingContent />
            ) : searchResults?.length === 0 ? (
              <div>
                <p><b>No results found</b></p>
                <p>We didn’t find any goods or services that match your search. Please check your
                search and try again.</p>
              </div>
            ) : (
              searchResults?.map(({ name, number, id, suggestions }) => {
                return (
                  <SearchResultListItem
                    key={id}
                    name={name}
                    number={number}
                    suggestions={suggestions}
                    handleAdd={handleAdd}
                    picked={picked}
                  />
                );
              })
            )}
          </ul>
        </div>
        <div className="search-container search-added ac-column">
          <div className="ac-header">
            <h3>Goods &amp; Services</h3>
          </div>
          <ul className="selected-class-container">
            {selections.map((selectionItem) => {
              return (
                <li key={selectionItem.number} className="selected-class">
                  <div className="flex-center-between">
                    <span className="class-title">
                      Class {selectionItem.number}
                    </span>
                    <IconButton
                      aria-label="delete"
                      className="remove-button"
                      onClick={() => handleRemoveClass(selectionItem)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </div>
                  <ul>
                    {selectionItem.suggestions.map((suggestion) => (
                      <PillDelete
                        key={suggestion.id}
                        handleRemove={handleRemove}
                        suggestion={suggestion}
                      />
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
