import React from "react";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <div id="page" class="site">
      <AppHeader />
      <div id="content" class="site-content">
        <section class="section-blog">
          <div class="container">
            <div class="blogs-feature">
              <Link
                to="/may-the-fourth-be-with-you/"
                id="post-260390"
                class="feature post-260390 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <div class="feature-content">
                  <h4>May the fourth be with you!</h4>
                </div>
              </Link>
            </div>

            <div class="flex-title">
              <h2 class="section-title">Latest News</h2>
            </div>

            <div class="blogs-grid">
              <Link
                to="/trademark-filings-in-australia-are-up/"
                id="post-260394"
                class="post-260394 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/05/graph-4737109_1920-740x480.jpg"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                  fetchpriority="high"
                />{" "}
                <h4>Trademark filings in Australia are up!</h4>
                <p class="date">April 27, 2022</p>
              </Link>
              <Link
                to="/au-domain-names/"
                id="post-260373"
                class="post-260373 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/03/photoholgic-jK9dT34TfuI-unsplash-740x480.jpg"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>.au domain names</h4>
                <p class="date">March 15, 2022</p>
              </Link>
              <Link
                to="/international-womens-day-2022/"
                id="post-260370"
                class="post-260370 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/04/people-7093682_1920-740x480.png"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>International Women's Day 2022</h4>
                <p class="date">March 8, 2022</p>
              </Link>
              <Link
                to="/phasing-out-of-the-australian-official-journal-of-trade-marks/"
                id="post-260364"
                class="post-260364 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2021/12/man-6671454_1920-740x480.jpg"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>
                  Phasing out of the Australian Official Journal of Trade Marks
                </h4>
                <p class="date">December 3, 2021</p>
              </Link>
              <Link
                to="/malaysia-joins-the-madrid-protocol-for-international-trademarks/"
                id="post-260367"
                class="post-260367 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/04/malaysia-645561_1920-740x480.png"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                  srcset="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/04/malaysia-645561_1920-740x480.png 740w, https://trademarkable.flywheelsites.com/wp-content/uploads/2022/04/malaysia-645561_1920-300x196.png 300w"
                  sizes="(max-width: 740px) 100vw, 740px"
                />{" "}
                <h4>
                  Malaysia joins the Madrid Protocol for International
                  Trademarks
                </h4>
                <p class="date">January 1, 2020</p>
              </Link>
              <Link
                to="/canada-joins-the-madrid-protocol-for-international-trademarks/"
                id="post-260377"
                class="post-260377 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorised"
              >
                <img
                  width="740"
                  height="480"
                  src="https://trademarkable.flywheelsites.com/wp-content/uploads/2022/04/canada-27003_1280-740x480.png"
                  class="attachment-blog-thumb size-blog-thumb wp-post-image"
                  alt=""
                  decoding="async"
                />{" "}
                <h4>
                  Canada joins the Madrid Protocol for International Trademarks
                </h4>
                <p class="date">June 17, 2019</p>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
