import React from 'react'
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
export default function Post5() {
  return (
    <div id="page" class="site">
      <AppHeader />
      <div id="content" class="site-content">
        <section class="section-blog">
          <div class="container">
            <div
              id="post5"
              class="blog-post_feature"
            ></div>
          </div>
          <div class="container container-md">
            <div class="page-content">
              <h1>
                Malaysia joins the Madrid Protocol for International Trademarks
              </h1>
              <p>
                On 27 December 2019, the Madrid Protocol entered into force in
                Malaysia. Accordingly, it is now possible for Australian
                businesses to protect their trademarks in Malaysia through an
                International registration.
              </p>
            </div>
          </div>
        </section>
      </div>
      <AppFooter />
    </div>
  );
}
