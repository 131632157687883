import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PublicIcon from "@mui/icons-material/Public";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from '@mui/icons-material/Business';
import { computeAddress, getCountryDialCode } from "../utils/utils";
import { SERVICE_CHARGE_COST, CLASS_COST } from "../Application";

const calculateGST = () => {
return (SERVICE_CHARGE_COST/11).toFixed(2)
};

function OrderSummary({apiResult, userInput, totalCost, selections}) {

  const formValues = {...userInput}
  return (
    <div className="ac-body form-summary">
      <div>
        <h3>Summary</h3>
        <span>Please ensure that your details are correct.</span>
      </div>
      <div className="trademark-details summary-section">
        <h4>Trademark</h4>
        <div className="summary-item">
          {formValues.trademarkType === "1" ? (
            <span>{formValues.trademarkText}</span>
          ) : (
            <img
              src={URL.createObjectURL(formValues.trademarkLogo)}
              alt="trademark logo"
            />
          )}
        </div>
        <br />
        <h4>GOODS & Services</h4>
        {[...selections]
          ?.sort((a, b) => a.number - b.number)
          .map((selected, index) => (
            <div className="small-item" key={index}>
              Class {selected.number}:{" "}
              {selected.suggestions.map(({ name }) => name).join("; ")}
            </div>
          ))}
      </div>
      <div className="owner-details summary-section">
        <h4>Owner</h4>
        <div className="summary-item">
          {formValues.ownerType === "1" ? (
            <>
              <BusinessIcon fontSize="small" color="action" />
              <span>
                {formValues.ownerName} {formValues.country.countryCode === "AU" && `(ACN: ${formValues.abnAcn})`}
              </span>
            </>
          ) : (
            <>
              <PersonIcon fontSize="small" color="action" />
              {formValues.firstName} {formValues.lastName}
            </>
          )}
        </div>
        <div className="summary-item">
          <PlaceIcon fontSize="small" color="action" />
          <span>{computeAddress(formValues)}</span>
        </div>
        <div className="summary-item">
          <PublicIcon fontSize="small" color="action" />
          <span>{formValues.country.countryName}</span>
        </div>
        <div className="summary-item">
          <EmailIcon fontSize="small" color="action" />
          <span>{formValues.email}</span>
        </div>
        <div className="summary-item">
          <PhoneAndroidIcon fontSize="small" color="action" />
          <span>{getCountryDialCode(formValues.country?.countryCode)} {formValues.phone}</span>
        </div>
      </div>
      <div className="cost-details summary-section">
        <h4>Cost breakdown</h4>
        <div className="cost-details-item">
          <span>Filing charge</span>
          <span>$330</span>
        </div>
        {[...selections]
          ?.sort((a, b) => a.number - b.number)
          .map(({ number }) => (
            <div key={number} className="cost-details-item">
              <span>Class {number}</span>
              <span>${CLASS_COST}</span>
            </div>
          ))}
      </div>
      <div className="cost-details summary-section">
        <div className="total-details">
          <span>
            Total{" "}
            {formValues.country?.countryCode === "AU" && <span className="font11">
              (incl. GST of ${calculateGST()})
            </span>}
          </span>
          <span>${totalCost}</span>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
